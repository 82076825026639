<template>
    <!--	根据路由参数加载对应的子系统-->
    <component :is="system_key" class="h-100 w-100"></component>
</template>

<script>
import { onMounted, reactive, toRefs, watch } from "@vue/composition-api";
import { routerParams } from "@/libs/utils/routerParams";
import store from "@/store";

export default {
    name: "SubSystems",
    components: {
        airConditioningVentilation: () =>
            import("./components/air-conditioning-ventilation"),
        energyManagement: () => import("./components/energy-management"),
        coldSource: () => import("./components/cold-source"),
        electricalSubstation: () =>
            import("./components/electrical-substation"),
        intelligentElevator: () => import("./components/intelligent-elevator"),
        floodlighting: () => import("./components/floodlighting"),
        intelligentEnvironment: () =>
            import("./components/intelligent-environment"),
        intelligentFireFighting: () =>
            import("./components/intelligent-fire-fighting"),
        intelligentLighting: () => import("./components/intelligent-lighting"),
        intelligentSecurity: () => import("./components/intelligent-security"),
        waterAlarm: () => import("./components/water-alarm"),
        emergencyEvacuation: () => import("./components/emergency-evacuation"),
        waterSupplyDrainage: () => import("./components/water-supply-drainage"),
        parking: () => import("./components/parking"),
        hiddenConstructionWork: () =>
            import("./components/hidden-construction-work"),
        heatSource: () => import("./components/heat-source"),
    },
    setup() {
        const event = reactive({
            system_key: routerParams("system_key"),
        });
        onMounted(() => {});
        watch(
            () => store.state.menus.current.params.system_key,
            (val) => {
                event.system_key = val;
            }
        );
        return {
            ...toRefs(event),
        };
    },
};
</script>

<style lang="scss" scoped>
</style>
